import axiosInstance from '../../../helpers/axios';

export const getBoards = (query) => {
  return axiosInstance.get(`board/get_by_query${query ? query : ''}`);
};

export const postBoard = (data) => {
  return axiosInstance.post('board/store', data);
};

export const patchBoard = ({ boardId, data }) => {
  return axiosInstance.patch(`board/${boardId}`, data);
};

export const deleteBoard = (boardId) => {
  return axiosInstance.delete(`board/${boardId}`);
};
