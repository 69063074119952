import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPins,
  postPin,
  patchPin,
  deletePin,
  getLatestPins,
  postLike,
  postComment,
  deleteLikes,
  getComments,
  deleteComment,
} from './apis';

export const fetchPins = createAsyncThunk(
  'pin/fetchPins',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getPins(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchLatesPins = createAsyncThunk(
  'pin/fetchLatesPins',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getLatestPins(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchDynamicePins = createAsyncThunk(
  'pin/fetchDynamicePins',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getLatestPins(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchComments = createAsyncThunk(
  'pin/fetchComments',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getComments(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createPin = createAsyncThunk('pin/createPin', async (data) => {
  const res = await postPin(data);
  return res.data.data;
});

export const createLike = createAsyncThunk('pin/createLike', async (data) => {
  const res = await postLike(data);
  return res.data.data;
});

export const createComment = createAsyncThunk(
  'pin/createComment',
  async (data) => {
    const res = await postComment(data);
    return res.data.data;
  }
);

export const updatePin = createAsyncThunk('pin/updatePin', async (data) => {
  const res = await patchPin(data);
  return res.data.data;
});

export const removePin = createAsyncThunk('pin/deletePin', async (pinId) => {
  await deletePin(pinId);
  return pinId;
});

export const removeComments = createAsyncThunk(
  'pin/deleteComment',
  async (commentId) => {
    await deleteComment(commentId);
    return commentId;
  }
);

export const removesLike = createAsyncThunk('pin/deleteLike', async (pinId) => {
  await deleteLikes(pinId);
  return pinId;
});
