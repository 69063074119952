/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchPins,
  createPin,
  updatePin,
  removePin,
  fetchLatesPins,
  createLike,
  removesLike,
  fetchComments,
  createComment,
  removeComments,
  fetchDynamicePins,
} from './action';

export const pinSlice = createSlice({
  name: 'pin',
  initialState: {
    pins: [],
    total_pins: 0,
    isPinLoading: false,
    comments: [],
    isLoading: true,
    isCommentLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchPins.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPins.fulfilled]: (state, { payload }) => {
      state.pins = payload;
      state.isLoading = false;
    },
    [fetchPins.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    [fetchComments.pending]: (state) => {
      state.isCommentLoading = true;
      state.error = null;
    },
    [fetchComments.fulfilled]: (state, { payload }) => {
      state.comments = payload;
      state.isCommentLoading = false;
    },
    [fetchComments.rejected]: (state) => {
      state.isCommentLoading = false;
      state.error = true;
    },
    [fetchLatesPins.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchLatesPins.fulfilled]: (state, { payload }) => {
      state.pins = payload.pins;
      state.total_pins = payload.total;
      state.isLoading = false;
    },
    [fetchLatesPins.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    [fetchDynamicePins.pending]: (state) => {
      state.isPinLoading = true;
      state.error = null;
    },
    [fetchDynamicePins.fulfilled]: (state, { payload }) => {
      state.pins = [
        ...state.pins,
        ...payload.pins.filter(
          (newPin) =>
            !state.pins.some((existingPin) => existingPin._id === newPin._id)
        ),
      ]; // Append new pins while avoiding duplicates

      state.total_pins = payload.total; // Update total count
      state.isPinLoading = false; // Set loading state to false
    },

    [fetchDynamicePins.rejected]: (state, { error }) => {
      state.isPinLoading = false; // Reset loading state
      state.error = error.message; // Store the error message
    },

    [fetchDynamicePins.rejected]: (state) => {
      state.isPinLoading = false;
      state.error = true;
    },
    [createPin.fulfilled]: (state, { payload }) => {
      state.pins.push(payload);
    },
    [createComment.fulfilled]: (state, { payload }) => {
      state.comments.push(payload);
      state.pins = state.pins.map((pin) =>
        pin._id === payload.pin_id
          ? { ...pin, commentsCount: pin.commentsCount + 1 }
          : pin
      );
    },
    [createLike.fulfilled]: (state, { payload }) => {
      state.pins = state.pins.map((pin) =>
        pin._id === payload.pin_id
          ? { ...pin, is_liked: true, likesCount: pin.likesCount + 1 }
          : pin
      );
    },
    [updatePin.fulfilled]: (state, { payload }) => {
      const findBoard = state.pins.find((pin) => pin._id === payload._id);
      Object.assign(findBoard, payload);
    },
    [removePin.fulfilled]: (state, { payload }) => {
      const newPins = state.pins.filter((pin) => pin._id !== payload);
      state.pins = newPins;
    },
    [removeComments.fulfilled]: (state, { payload }) => {
      const newComments = state.comments.filter(
        (comment) => comment._id !== payload
      );
      state.comments = newComments;
    },
    [removesLike.fulfilled]: (state, { payload }) => {
      state.pins = state.pins.map((pin) =>
        pin._id === payload
          ? { ...pin, is_liked: false, likesCount: pin.likesCount - 1 }
          : pin
      );
    },
  },
});

export default pinSlice.reducer;
